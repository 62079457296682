<template>
  <div class="home-view">
    <header>
      <img src="images/logo.svg" alt="Leaders.org" />
    </header>
    <cs-spinner v-if="$apollo.queries.eventresponse.loading || submitting" />



    <div v-if="eventresponse" class="container">

      <div class="row">
        <div class="col-left">

          <!-- Event Response - Invited -->
          <div slot="body" v-if="eventresponse.status == 'invited'">
            <div class="invite-content">
              <h4 class="cs-textstyle-item-heading heading-margin">Hi {{ person.first_name }}!</h4>
              <p class="cs-textstyle-paragraph">
                We'd like to invite you to join {{ event.title }} on {{ event.starts_at | moment('LL') }} at {{ event.starts_at | moment('LT') }} (details below).
                As space is very limited, we would be grateful if you could RSVP at your earliest convenience.
              </p>
              <p class="cs-textstyle-paragraph">If you have any questions, please contact Janakan Arulkumarasan at <a href="mailto:janakan@leaders.org">janakan@leaders.org</a>. </p>
            </div>
            <div class="rsvp-buttons">
              <cs-button :disabled="submitting" @click="rsvp('attending')">I'd love to join - please reserve my seat!</cs-button>
              <cs-button :disabled="submitting" variant="danger" @click="rsvp('declined')">Unfortunately, I'm not available.</cs-button>
            </div>
          </div>
          
          <!-- Event Response - Attending -->
          <div slot="body" v-if="eventresponse.status == 'attending'">
            <div class="invite-content">
              <h4 class="cs-textstyle-item-heading heading-margin">Hi {{ person.first_name }}!</h4>
              <p class="cs-textstyle-paragraph">
                You have indicated that you are <b>attending</b> {{ event.title }} on {{ event.starts_at | moment('LL') }} at {{ event.starts_at | moment('LT') }} (details below).
                A calendar event has been sent to your registered email address.
              </p>
              <p class="cs-textstyle-paragraph">If you have any questions, please contact Janakan Arulkumarasan at <a href="mailto:janakan@leaders.org">janakan@leaders.org</a>. </p>
            </div>
            <div class="rsvp-buttons">
              <cs-button :disabled="submitting" variant="danger" @click="rsvp('invited')">Cancel RSVP</cs-button>
            </div>
          </div>
          
          <!-- Event Response - Declined -->
          <div slot="body" v-if="eventresponse.status == 'declined'">
            <h4 class="cs-textstyle-item-heading heading-margin">Hi {{ person.first_name }}!</h4>
            <p class="cs-textstyle-paragraph">
              You have indicated that you are <b>not attending</b> {{ event.title }} on {{ event.starts_at | moment('LL') }} at {{ event.starts_at | moment('LT') }} (details below).
              Your seat will be shared with the next guest on the invitation list.
            </p>
            <p class="cs-textstyle-paragraph">To request a re-invitation, or if you have any questions, please contact Janakan Arulkumarasan at <a href="mailto:janakan@leaders.org">janakan@leaders.org</a>. </p>
          </div>
          
          <div class="seperator"></div>

          <div class="event-block">
            <h4 class="cs-textstyle-item-heading heading-margin">Event Details</h4>
            <cs-card>
              <img :src="event.photo_url" slot="media" >
              <div slot="body" class="event-card">
                <div class="tags">
                  <cs-tag>Leaders.org {{ event.network.name }}</cs-tag>
                  <cs-tag v-for="tag of event.tags" :key="tag">{{ tag }}</cs-tag>
                </div>
                <h4 class="cs-textstyle-item-heading card-heading">{{ event.title }}</h4>
                <p class="cs-textstyle-paragraph event-desc">{{ event.description }}</p>

                <div class="event-row">
                  <div class="event-left-col">
                    <div class="event-info">
                      <div class="info-icon"><img src="images/cs-icons-calendar.svg" alt="" /></div>
                      <div class="info-detail">
                        <p class="cs-textstyle-paragraph-bold no-margin">{{ event.starts_at | moment('LL')}}</p>
                        <p class="cs-textstyle-paragraph no-margin">{{ event.starts_at | moment('LT')}} - {{ event.ends_at | moment('LT') }}</p>
                      </div>
                    </div>

                    <div class="event-info">
                      <div class="info-icon"><img src="images/cs-icons-calendar.svg" alt="" /></div>
                      <p class="cs-textstyle-paragraph no-margin" v-if="event.price">{{ event.currency }} {{ event.price}}.</p>
                      <p class="cs-textstyle-paragraph no-margin" v-else>Free, by invitation only.</p>
                    </div>
                    
                  </div>
                  <div class="event-seperator"></div>
                  <div class="event-right-col">
                    <p class="cs-textstyle-paragraph-bold no-margin">Hosted by</p>
                    <div class="event-m-head">
                      <p class="cs-textstyle-paragraph no-margin">{{ event.host.name }}</p>
                      <p class="cs-textstyle-paragraph-small no-margin">{{ event.organization.name }}.</p>
                    </div>
                  </div>

                </div>

                
              </div>
            </cs-card>
          </div>

          
        </div>
        <div class="col-right">
          <h4 class="cs-textstyle-item-heading heading-margin">Event Location</h4>
          <div class="location-card">
            <iframe
                class="map"
                loading="lazy"
                allowfullscreen
                :src="mapSrc"
              ></iframe>
            <div slot="body" class="venue-card">
              <h4 class="cs-textstyle-item-heading no-margin">{{ event.venue.venue_name }}</h4>

              <div class="venue-list">
                <h5 class="cs-textstyle-detail-heading no-margin">Address:</h5>
                <p class="cs-textstyle-paragraph no-margin">{{ event.venue.street_address }}</p>
              </div>

              <div class="venue-list">
                <h5 class="cs-textstyle-detail-heading no-margin">Directions:</h5>
                <p class="cs-textstyle-paragraph no-margin">{{ event.venue.access_instructions }}</p>
              </div>
              
              <div class="venue-list">
                <h5 class="cs-textstyle-detail-heading no-margin">Room: {{ event.venue.room_name }}</h5>
                <p class="cs-textstyle-paragraph no-margin">{{ event.venue.description }}</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <!-- <p>{{ eventresponse.status }}</p> -->
    </div>

    <footer>
      <div class="footer-row">
        <div class="copyright">
          <img class="footer-logo" src="images/logo.svg" alt="Leaders.org"/>
          <p class="cs-textstyle-paragraph no-margin">© 2022 Leaders.org, All Rights Reserved.</p>
        </div>
        <div class="connect-us">
          <div class="address">
            <img src="images/cs-icons-marker.svg">
            <span class="cs-textstyle-paragraph no-margin">#18 Centennial Tower,<br>Singapore 039190</span>
          </div>
          <ul class="social">
            <li><a href="https://www.facebook.com/leadersdotorg"><img src="images/cs-icons-facebook-filled.svg" alt=""></a></li>
            <li><a href="https://www.linkedin.com/company/leadersdotorg"><img src="images/cs-icons-linkedin-filled.svg" alt=""></a></li>
            <li><a href="https://www.twitter.com/leadersdotorg"><img src="images/cs-icons-twitter-filled.svg" alt=""></a></li>
          </ul>
        </div>
      </div>
    </footer>

  </div>
</template>
<script>
import GetEventResponse from '@/gql/GetEventResponse.gql';
import RSVPEvent from '@/gql/RSVPEvent.gql';

export default {
  data() {
    return {
      id: this.$route.query.id,
      submitting: false,
    };
  },
  apollo: {
    eventresponse: {
      query: GetEventResponse,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
    }
  },
  computed: {
    event() {
      return this.eventresponse && this.eventresponse.event || null;
    },
    person() {
      return this.eventresponse && this.eventresponse.person || null;
    },
    mapSrc() {
      if (this.eventresponse) {
        const url = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(this.eventresponse.event.venue.street_address)}&key=AIzaSyC5g9djzDZAc9Hkl7VtG2EGTy6rTMjrFPU`;
        return url;
      }
      return '';
    },
  },
  methods: {
    async rsvp(status) {
      this.submitting = true;
      await this.$apollo.mutate({
        mutation: RSVPEvent,
        variables: {
          event_id: this.eventresponse.event.id,
          person_id: this.eventresponse.person.id,
          status,
        },
      });
      this.$apollo.queries.eventresponse.refetch();
      this.submitting = false;
    },
  },
}
</script>
<style scoped>

p.cs-textstyle-paragraph a {
    color: #0D2183;
    text-decoration: none;
}

header {
    padding: 20px 10px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 5px 12px 0px #00000008;
}
header img {
  height: 54px;
}

.seperator {
  height: 1px;
  border-bottom: 1px dashed #ccc;
  margin: 40px 0 20px;
}

.container {
  max-width: 1180px;
  margin: 70px auto;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row {display: flex;flex-direction: row;}

.col-left {
    flex: 1;
    padding-right: 70px;
    width: 100%;
}

.col-right {
    max-width: 420px;
    width: 100%;
}

.heading-margin {
  margin: 0 0 10px;
}

.invite-content {
  margin-bottom: 30px;
}

.rsvp-buttons {
  display: flex;
  gap: 10px;
}

.event-block {
    margin-top: 40px;
}

.card-heading {
  margin: 10px 0 0;
  color: var(--cs-gray-06);
}

.event-block .cs-card__media--top {
    height: 200px !important;
}

.event-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.event-desc{
  margin: 0 0 15px;
}

.event-seperator {
    width: 1px;
    border-right: 1px dashed #ccc;
    margin: 0 10px;
}

.event-m-head {
  margin: 5px 0 0;
}

.no-margin {
  margin: 0;
}

.event-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
}

.event-info:last-child {
    margin-bottom: 0;
}

.info-icon {
    margin-right: 10px;
    line-height: 1;
}

.location-card {
    border: 1px solid #e6eaea;
    background: #fff;
    border-radius: 5px;
}

.venue-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tags {
  display: flex;
  gap: 10px;
}
.cs-card {
  margin: 0px;
}

.map {
  border: none;
  width: 100%;
  height: 300px;
}

footer {
  background-color: #000C47;
  padding: 30px 0;
  color: #fff;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 15px;
}

img.footer-logo {
    filter: brightness(100);
    display: block;
    margin-bottom: 15px;
}

.connect-us {
    text-align: right;
}

.address img {
  vertical-align: top;
  border-style: none;
}

.address span {
    color: #F5F7F6;
    margin-left: 15px;
    display: inline-block;
}

ul.social {
    margin: 15px 0 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

ul.social li {
    list-style: none;
    margin-left: 30px;
}

ul.social li img {
    height: 24px;
}

@media (max-width: 1200px) {
  .col-left {
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 720px;
    margin: 50px auto;
  }
  .row {
    flex-direction: column;
  }
  .col-left {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .seperator{
    margin: 30px 0 20px;
  }

  .event-block {
    margin-top: 30px;
  }
  .col-right{
    max-width: 100%;
  }

  .footer-row {
    max-width: 720px;
  }

}

@media (max-width: 767px) {
  header {
    padding: 15px 10px;
  }
  header img, img.footer-logo {
    height: 46px;
  }

  .container[data-v-9ea40744] {
    margin: 30px auto;
  }

  .seperator{
    margin: 25px 0 20px;
  }

  .event-block {
    margin-top: 25px;
  }

  .col-left {
    margin-bottom: 30px;
  }
  .tags{
    flex-wrap: wrap;
  }
  .rsvp-buttons {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .container {
    margin: 30px auto;
    padding: 0 20px;
  }
  .event-row {
    flex-direction: column;
  }
  .event-seperator {
    height: 1px;
    width: 100%;
    border-right: none;
    border-bottom: 1px dashed #ccc;
    margin: 15px 0;
  }

  .footer-row {
    flex-direction: column-reverse;
  }

  .copyright {
    margin-top: 25px;
    text-align: center;
  }

  img.footer-logo {
    margin: 0 auto 15px;
  }

  .connect-us {
    text-align: center;
  }

  .address img {
    display: block;
    margin: 0 auto 15px;
  }

  ul.social {
    justify-content: center;
  }
}
</style>
