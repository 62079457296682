import Vue from 'vue';
import apolloClient from '@/services/apollo';
import VueApollo from 'vue-apollo';
import VueMoment from 'vue-moment';

import 'cs-components';
import 'cs-components/dist/csuite.css';

import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(VueMoment);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
